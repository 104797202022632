import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Container from "./bootstrap/container"
import Logo from "./logo-image"
import BandsDiamond from "./bands-diamond"
import BandsRectangle from "./bands-rectangle"
import { FaBars } from "react-icons/fa"

const Header = ({ siteTitle }) => (
  <header style={{
    position: `relative`
  }}>
    <Container>
      <div style={{ position: `relative` }}>
        <div className="logo-gradient" style={{
          background: `linear-gradient(90deg, rgba(251,146,1,0.7) 0%, rgba(210,52,100,0.7) 27.18%)`,
          width: `27.5rem`,
          height: `6.8rem`,
          position: `absolute`,
          top: `0`,
          left: `-10rem`,
          zIndex: `-1`
        }} />
      </div>
      <nav className="navbar navbar-expand-lg">
        <Link to="/" className="navbar-brand">
          <Logo className="logo" style={{
            width: `250px`,
            margin: `1rem -1rem`,
            paddingRight: `2px`
          }} />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" style={{
          borderRadius: `50%`,
          border: `2px solid #fff`,
          width: `46px`,
          height: `46px`,
          padding: `0`
        }}>
          <FaBars color="#fff" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto">
            {/* <Link to="/about/" className="nav-item nav-link" style={{ color: `#fff` }}>About</Link> */}
            {/* <Link to="/conference/" className="nav-item nav-link" style={{ color: `#fff` }}>Conference</Link> */}
            <a href="https://events.ringcentral.com/events/smarter-working-live-2024/registration" className="nav-item nav-link" style={{ color: `#fff` }} target="_blank" rel="noreferrer">Conference</a>
            <Link to="/awards/" className="nav-item nav-link" style={{ color: `#fff` }}>Awards</Link>
            {/* <Link to="/categories/" className="nav-item nav-link" style={{ color: `#fff` }}>Categories</Link> */}
            {/* <Link to="/agenda/" className="nav-item nav-link" style={{ color: `#fff` }}>Agenda</Link> */}
            {/* <Link to="/winners/" className="nav-item nav-link" style={{ color: `#fff` }}>Winners</Link> */}
            {/* <Link to="/shortlist/" className="nav-item nav-link" style={{ color: `#fff` }}>Shortlist</Link> */}
            {/* <Link to="/partners/" className="nav-item nav-link" style={{ color: `#fff` }}>Judges &amp; Partners</Link> */}
            {/* <Link to="/sponsors/" className="nav-item nav-link" style={{ color: `#fff` }}>Sponsors</Link> */}
            {/* <Link to="/register/" className="nav-item nav-link" style={{ color: `#fff` }}>Login / Register</Link> */}
            {/* <Link to="/enter/" className="nav-item nav-link" style={{ color: `#fff` }}>Enter</Link> */}
            {/* <a href="https://get.govnewsdirect.com/swl21-want-to-be-a-partner/" className="nav-item nav-link" style={{ color: `#fff` }} target="_blank" rel="noreferrer" hidden>Sponsor</a> */}
            {/* <Link to="/contact/" className="nav-item nav-link" style={{ color: `#fff` }}>Contact</Link> */}
            {/* <a href="https://2023.smarterworkinglive.com" className="nav-item nav-link" style={{ color: `#fff` }} target="_blank" rel="noreferrer">2023 Event</a> */}
            <a href="https://get.govnewsdirect.com/swl24-want-to-be-a-partner/" className="nav-item nav-link" style={{ color: `#fff` }} target="_blank" rel="noreferrer">Sponsorship</a>
          </div>
        </div>
      </nav>
    </Container>
    {/* <BandsDiamond className="d-none d-md-block" style={{
      position: `absolute`,
      top: `-50px`,
      left: `50%`
    }} /> */}
    <BandsRectangle style={{
      width: `120px`,
      height: `20px`,
      marginTop: `-10px`
    }}/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
